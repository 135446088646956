import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BlogProfile from "../../components/blog-profile"
import SignupBanner from "../../components/signupBanner"

export default function BlogPostTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} description={excerpt} />
      <div className="container mx-auto pt-12 pb-48  px-6 " id="blog-list">
        <div className="lg:mx-32 xl:mx-48">
          <Link to={"/blog"} className="mb-4 block">
            ← All blog posts
          </Link>
          <time className="uppercase tracking-wider mb-2 block">
            {frontmatter.date}
          </time>
          <h1 className="mb-2 text-4xl font-bold leading-snug tracking-wide">
            {frontmatter.title}
          </h1>
          <BlogProfile className="mt-8" post={frontmatter} />

          <main
            id="post"
            className="blog-post text-lg mt-8 block"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {frontmatter.cta && (
            <SignupBanner>Try Cargo-Planner for free</SignupBanner>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
        authorTitle
        cta

        profile {
          childImageSharp {
            gatsbyImageData(width: 200)
          }
        }
      }
    }
  }
`
