import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const BlogProfile = ({ post, className }) => {
  return (
    <div className={"w-72 flex mb-4 text-sm " + (className || "")}>
      <div>
        <GatsbyImage
          image={post.profile.childImageSharp.gatsbyImageData}
          className="w-12 mr-4 rounded-full bg-neutral-500"
          alt={post.author}
        />
      </div>
      <div className="mt-1">
        <p className="font-bold">{post.author}</p>
        <p>{post.authorTitle}</p>
      </div>
    </div>
  )
}
export default BlogProfile
